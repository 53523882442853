import { Search } from '@element-plus/icons';
import { defineComponent } from 'vue';
export default defineComponent({
    components: {
        Search
    },
    emits: ['pass-searched-keyword', 'data-to-be-added'],
    props: ['placeholder', 'data'],
    data() {
        return {
            searchInput: '',
            links: [],
            sampleSearches: [],
            timeout: null,
            searchSuggestions: [],
        };
    },
    watch: {
        searchInput: {
            immediate: true,
            deep: true,
            handler(value) {
                if (value) {
                    this.$emit('pass-searched-keyword', value);
                }
            }
        },
        data: {
            immediate: true,
            deep: true,
            handler(value) {
                if (value) {
                    this.links = value;
                }
            }
        }
    },
    methods: {
        loadAll() {
            this.sampleSearches = [
                { value: 'Plumbing' },
                { value: 'Planning' },
                { value: 'Electrical' },
            ];
        },
        querySearchAsync(queryString, cb) {
            const results = queryString
                ? this.links.filter((_, index) => this.createFilter(queryString, index))
                : this.sampleSearches;
            if (queryString) {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    cb(results);
                }, 1500 * Math.random());
            }
            else {
                cb(results);
            }
        },
        createFilter(queryString, index) {
            return (restaurant) => {
                return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === index);
            };
        },
        handleSelect(item) {
            this.$emit('data-to-be-added', item);
            this.searchInput = '';
        },
    },
    async mounted() {
        await this.loadAll();
    },
});
