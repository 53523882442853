<template>
    <BaseModal
      :show="show"
      :showCancelBtn="false"
      :showSubmitBtn="false"
      :showDefaultCloseBtn="false"
      :hasActions="false"
    >
    <div class="modal-content flex-column jc-sb">
      <div class="flex-column">
          <div class="modal-content__modal-header flex-row jc-sb ai-c">
              <h2 class="modal-header__title default-text">All categories</h2>
              <div class="modal-content__header-icon">
                <inline-svg class="cursor-pointer" @click="close" :src="require(`@/assets/icons/x.svg`)"></inline-svg>
              </div>
          </div>
          <div class="modal-content__container flex-row" v-if="categoryList.length">
            <CategoryItemComponent
              v-for="(category, index) in categoryList"
              :key="index" :index="index" :data="category"/>
          </div>
          <div class="modal-content__container flex-row" v-else>
            <span>
              No categories selected
            </span>
          </div>
      </div>
    </div>
  </BaseModal>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import BaseModal from '@/core/components/ui/BaseModal.vue';
import { PROJECTS_STORE } from '@/store/modules/projects';

import CategoryItemComponent from './CategoryItemComponent.vue';

export default defineComponent({
  components: {
    BaseModal,
    CategoryItemComponent
  },

  emits: ['close'],

  props: ['show'],

  computed: {
    ...mapGetters(PROJECTS_STORE, [
      'categoryList'
    ]),
  },

  methods: {
    close() {
      this.$emit('close');
    }
  },
});
</script>
<style lang="scss" scoped>
.modal-content {
  max-width: 400px;
  max-height: 500px;

  &__container {
    flex-wrap: wrap;
  }
}
</style>
