<template>
  <span class="item default text">
    <span>
      <img src="@/assets/icons/sample-skill-icon.svg">
    </span>
      {{ data.value }}
    <span class="cursor-pointer" @click="removeCategory">
      <el-icon :size="15" color="#fff"><Minus/></el-icon>
    </span>
  </span>
</template>
<script>
import { Minus } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { PROJECTS_STORE } from '@/store/modules/projects';

export default defineComponent({
  components: {
    Minus
  },

  props: ['data', 'index'],

  computed: {
    ...mapGetters(PROJECTS_STORE, [
      'categoryList'
    ]),
  },

  methods: {
    ...mapActions(PROJECTS_STORE, [
      'setCategoryList'
    ]),

    removeCategory() {
      this.categoryList.splice(this.index, 1);
      this.setCategoryList([...this.categoryList]);
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.item {
   height: 40px;
   padding: 0 1rem;
   display:flex;
   gap: .6rem;
   justify-content: space-between;
   align-items: center;
   border-radius: 100px;
   border: 1px solid rgba(12, 15, 74, 0.2);
   font-size: 12px;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: 0.4000000059604645px;
   text-align: left;
   white-space: nowrap;

   .el-icon {
    background: rgba(12, 15, 74, 0.5);
    border-radius: 100%;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    display:flex;
    align-items: center;
    justify-content: center;
  }
}

@include media-md-max-width() {
  .item {
    font-size: 10px;
  }
}
</style>
