<template>
  <div class="header-container flex-row ai-c jc-sb">
    <div class="header-container__wrapper flex-row ai-c">
      <AutoCompleteSearch
        :data="loadedCategories"
        @data-to-be-added="addDataToCategoryList"
        @pass-searched-keyword="getCategories"
        placeholder="Search job categories or keywords"
        :disabled="disable"/>
      <div class="address-filter-container flex-row ai-c jc-c">
        <el-form :model="findJobsSearchValue" class="flex-row ai-c">
          <el-input
            class="search-input w-50 m-2"
            placeholder="Postcode"
            v-model="findJobsSearchValue.postcodeValue"
            v-debounce:100="onSearchPostcode"
            :disabled="disable"
          >
            <template #prefix>
              <el-icon class="el-input__icon"><LocationFilled /></el-icon>
            </template>
          </el-input>
          <el-select
            class="members-role"
            v-model="findJobsSearchValue.distanceValue"
            filterable
            placeholder="select range"
            @change="onSelectDistance"
            :disabled="disable">
            <el-option
              v-for="item in distanceOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              />
            <template #prefix>
              <el-icon class="el-input__icon"><img src="@/assets/icons/range-icon.svg"></el-icon>
            </template>
          </el-select>
        </el-form>
      </div>
    </div>
    <div class="header-container__wrapper flex-row ai-c">
      <DisplayViewComponent :viewType="viewType" @set-view="setView"/>
    </div>
  </div>
</template>
<script>
import { LocationFilled } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import AutoCompleteSearch from '@/core/components/ui/AutoCompleteSearch.vue';
import DisplayViewComponent from '@/modules/project-search/components/DisplayViewComponent.vue';
import { PROJECTS_STORE } from '@/store/modules/projects';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    AutoCompleteSearch,
    LocationFilled,
    DisplayViewComponent
  },

  emits: ['on-select-distance', 'on-search-postcode', 'set-view'],

  props: ['viewType', 'disable'],

  data() {
    return {
      distanceOptions: [
        {
          value: '1',
          label: '1 Mile',
        },
        {
          value: '3',
          label: '3 Miles',
        },
        {
          value: '5',
          label: '5 Miles',
        },
        {
          value: '10',
          label: '10 Miles',
        },
        {
          value: '20',
          label: '20 Miles',
        },
        {
          value: '50',
          label: '50 Miles',
        },
        {
          value: '',
          label: 'Nation wide',
        },
      ],

      loadedCategories: [],
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),
    ...mapGetters(PROJECTS_STORE, [
      'findJobsSearchValue',
      'searchCategories',
      'categoryList'
    ]),

    userPostcode() {
      return this.user?.postcode;
    }
  },

  created() {
    this.setPostcode();
  },

  methods: {
    ...mapActions(PROJECTS_STORE, [
      'setFindJobsSearchValue',
      'getProjectJobsCategoriesByKeywords',
      'setCategoryList'
    ]),

    async getCategories(keyword) {
      await this.getProjectJobsCategoriesByKeywords(keyword);
      let categories = [];

      categories = this.searchCategories.data.map((category) => {
        const plainCategory = { ...category };
        plainCategory.value = plainCategory.label.replace(/\n/g, '');
        delete plainCategory.label;
        return plainCategory;
      });

      const uniqueValues = [];

      this.loadedCategories = categories.filter((category) => {
        const isDuplicate = uniqueValues.includes(category.value);

        if (!isDuplicate) {
          uniqueValues.push(category.value);

          return true;
        }

        return false;
      });
    },

    addDataToCategoryList(category) {
      const newCategoryList = [
        ...this.categoryList,
        category
      ];

      this.setCategoryList(newCategoryList);
    },

    onSelectDistance(radius) {
      this.$emit('on-select-distance', radius);
    },

    onSearchPostcode(postcode) {
      this.$emit('on-search-postcode', postcode);
    },

    setPostcode() {
      this.setFindJobsSearchValue({ ...this.findJobsSearchValue, postcodeValue: this.userPostcode });
    },

    setView(screen) {
      this.$emit('set-view', screen);
    }
  },
});
</script>
<style lang="scss" scoped>
.header-container {

    &__wrapper {
        display:flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;

        :deep(.el-input) {
            width: 250px;
            height: 42px;

            .el-input__inner {
                border-radius: 100px;
                outline:none;
                border: none;
                padding: 20px 20px 20px 40px;
            }
        }

      .el-select {
          :deep(.el-input) {
              width: 150px;

              .el-input__inner {
                  padding-left: 40px !important;
              }
          }
      }

        :deep(.el-input__prefix) {
            left:10px;
            top: -2px;
            color:#111;
        }
    }
}
</style>
