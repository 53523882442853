<template>
    <el-card @click="routeToProjectDetailsPage(item)" v-for="(item, index) in data" :key="index" :class="{ 'tile-card': viewType === 'tile' || viewType === 'map' }">
        <div class="column-one" :class="{ 'full-width': type === 'tile' || type === 'map' }">
            <!-- <el-image
                class="attachment-image"
                fit="cover"
                :src="url"
                :preview-src-list="srcList"
                :append-to-body="true"
            >
                <template v-slot:error>
                <div class="image-slot">
                    <el-icon><Picture /></el-icon>
                </div>
                </template>
            </el-image> -->

             <ProjectItemThumbnail :project="item"/>

            <div class="project-details" :class="{ 'flex-column': type === 'tile' || type === 'map' }">
                <div class="project-label-container">
                    <p v-if="item.postcode" class="project-postcode"><span><el-icon :size="12" color="#0C0F4A"><LocationFilled /></el-icon></span>{{ item.postcode }}</p>
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="item.name"
                        placement="top"
                    >
                        <p class="project-name">{{item.name}}</p>
                    </el-tooltip>
                </div>
                <!-- DESKTOP SCREEN DESCRIPTION -->
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="item.description"
                    placement="top"
                >
                    <p class="project-description">
                        {{item.description}}
                    </p>
                </el-tooltip>
                <!-- DESKTOP SCREEN DESCRIPTION -->
            </div>
        </div>
        <!-- MOBILE SCREEN DESCRIPTION -->
        <!-- <el-tooltip
            class="box-item"
            effect="dark"
            :content="item.description"
            placement="top"
        >
            <p class="project-description show-in-mobile">
                {{item.description}}
            </p>
        </el-tooltip> -->
        <!-- MOBILE SCREEN DESCRIPTION -->
        <div class="column-two" :class="{ 'flex-column': type === 'tile' || type === 'map', 'full-width': type === 'tile' || type === 'map' }">
            <div class="box">
               <p class="label">Skill categories</p>
               <div class="categories">
                    <el-tooltip
                    v-for="(category, index) in visibleCategories(item.categories)"
                    :key="index"
                    class="box-item"
                    effect="dark"
                    :content="category.label"
                    placement="top">
                      <inline-svg v-if="category.iconName"
                        class="category-icon" width="17px" height="17px"
                        :src="require(`@/assets/category-icons/${category.iconName}.svg`)">
                      </inline-svg>
                      <inline-svg v-else
                        class="category-icon" width="17px" height="17px"
                        :src="require(`@/assets/icons/sample-skill-icon.svg`)">
                      </inline-svg>
                    </el-tooltip>
                  <p class="skill-count" v-if="remainingCategoriesCount(item.categories)">
                    {{ remainingCategoriesCount(item.categories) }}
                  </p>
                  <p class="data" v-if="!item.categories.length">No categories</p>
               </div>
            </div>
            <div class="box">
               <p class="label">Posted</p>
               <p class="data">{{ $filters.formatToDatetime(item?.dateCreated, 'DD/MM/YYYY') }}</p>
            </div>
            <div class="box">
               <p class="label">Distance</p>
               <p class="data" v-if="item?.distance">{{ item?.distance }} miles</p>
            </div>
        </div>
    </el-card>
</template>
<script>
import { LocationFilled } from '@element-plus/icons';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { mapGetters } from 'vuex';

import ProjectItemThumbnail from '@/modules/property-details-children/components/ProjectItemThumbnail.vue';
import { USERS_STORE } from '@/store/modules/users';

export default {
  components: {
    LocationFilled,
    // Picture,
    ProjectItemThumbnail
  },
  props: ['viewType', 'data'],

  created() {
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),
  },

  methods: {
    initialize() {
    },
    visibleCategories(categories) {
      return this.groupCategories(categories).slice(0, 3);
    },
    remainingCategoriesCount(categories) {
      return Math.max(0, this.groupCategories(categories).length - 3);
    },
    groupCategories(categories) {
      const groups = {};

      categories.forEach((category) => {
        const { label, iconName } = category;

        if (!groups[label]) {
          groups[label] = {
            label,
            iconName,
            categories: []
          };
        }

        groups[label].categories.push(category);
      });

      return Object.values(groups);
    }
  },

  setup(props) {
    /* eslint-disable global-require */
    const url = ref(`${require('@/assets/images/po-project-empty-placeholder.jpg')}`);
    const srcList = ref([
      `${require('@/assets/images/sample-image-three.jpg')}`,
    ]);
    const router = useRouter();

    const type = computed(() => {
      return props.viewType;
    });

    function routeToProjectDetailsPage(project) {
      router.push(`/projects/${project.refId}/project-details/overview`);
    }

    return {
      routeToProjectDetailsPage,
      url,
      srcList,
      type
    };
  },
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.el-card * p {
    margin: 0;
    color: #0C0F4A;
    font-family: Mulish;
    text-align: left;
}
.el-card.is-always-shadow {
    box-shadow: none;
}
.el-card {
    border-radius: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    // height: 120px;

    :deep(.el-card__body) {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        box-sizing: border-box;
        gap: 1rem;
    }
}
.column-one {
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;
    width:100%;
    // min-width: 200px
}
    .el-image {
        width: 80;
        height: 80px;
        border-radius: 10px;
        min-width: 84px;
    }

.project-details {
    display:flex;
    gap: 1.5rem;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 2rem;
}

.project-label-container {
    display: flex;
    flex-direction: column;
//    width: 35%;
}

.project-postcode {
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.25px;
    opacity: 0.5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;

    span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}
.project-name {
    font-family: Mulish;
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.25px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    line-height: 1.2em;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.project-description {
    font-family: Mulish;
    font-size: 13px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.25px;
    text-align: left;
    color: rgba(12, 15, 74, 0.5);
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2em;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.column-two {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.5rem;
    width:50%;
}
.box {
    background: rgba(12, 15, 74, 0.05);
    border-radius: 8px;
    width: 122px;
    height: 80px;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
}
.label {
    font-family: Mulish;
    font-size: 8px;
    font-weight: 400;
    letter-spacing: 0.25px;
    text-align: left;
    color: rgba(12, 15, 74, 0.7);
}
.data {
    font-family: Mulish;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.25px;
    text-align: left;
}
.categories {
    display: flex;
    gap:.6rem;

    .skill-count {
        background: rgba(12, 15, 74, 0.1);
        border-radius: 100%;
        display:flex;
        justify-content: center;
        align-items:center;
        font-family: Mulish;
        font-size: 10px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0.25px;
        text-align: center;
        width: 24px;
        height: 24px;
        min-width: 24px;
    }
    .category-icon {
      :deep(path) {
        fill: #0C0F4A;
        fill-opacity: 1;
      }
    }
}

.column-one.full-width {
    // width: 100%;
    flex-direction: column;
    gap: .7rem;

    .project-details {
        gap: .2rem;
        .project-label-container {
            width: 100%;
        }
        .project-description {
            width: 100%;
        }
    }
}
.column-two.full-width {
    width: 60%;
    .box {
        width: 100%;
        height: 46px;
        flex-direction: row;
        align-items:center;
    }
}
.flex-column {
    flex-direction: column;
}

@include media-md-max-width() {
    .column-one.full-width {
        flex-direction: row;

        .project-details {
            gap: 0;
        }
    }
  .project-details {
     flex-direction: column;
     gap: 0;
  }
  .project-name {
      font-size: 16px;
      line-height: 24px;
  }
  .project-description {
      font-size: 12px;
      line-height: 20px;
  }
}
@media screen and (max-width: 900px) {
    .el-card {
        :deep(.el-card__body) {
            flex-direction: column;
        }
    }

    .column-two, .column-two.full-width {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
        gap: .5rem;

        .box {
            width: 100%;
            background: rgba(12, 15, 74, 0.05);
            height: 80px;
            flex-direction: column;
            justify-content: space-between;
            padding: 1rem;
            box-sizing: border-box;
            align-items: flex-start;
        }
    }
}

@include media-xs-max-width() {
   .project-details {
        flex-direction: column;
        gap: 0;
    }
}
</style>
