<!-- eslint-disable import/no-cycle -->
<template>
    <section class="find-jobs-container" v-if="hasMainLayoutInitialized">
        <FindJobHeader
          :viewType="viewType"
          :disable="loading"
          @set-view="setView"
          @on-select-distance="onSelectDistance($event)"
          @on-search-postcode="onSearchPostcode($event)"/>
        <div class="skills-container">
          <CategoryItemComponent
            v-for="(category, index) in toShowCategoryList"
            :key="index"
            :index="index"
            :data="category"/>
          <div class="plus-count cursor-pointer" v-if="remainingCategoryList.length">
            <span class="count default-text"
              @click="setCategoriesModal(true)">{{ '+' + remainingCategoryList.length.toString() }}</span>
          </div>
        </div>
        <div class="find-jobs-content" :class="{ 'flex-row-layout': viewType === screenType.MAP, 'changeMaxWidth': viewType === screenType.TILE }">
            <el-scrollbar :class="{ 'grid-layout': viewType === screenType.TILE }">
              <DisplayTypeComponentMobile @set-view="setView"/>
              <ProjectCardItemComponent
                v-if="projects.length && !loading" :data="projects" :key="index" :viewType="viewType"/>
              <Loader :show="isLoading" v-if="loading"/>
              <EmptyState
                v-if="!loading && !projects.length"
                style="left: calc(50% - 406px)"
                message="There are no available jobs Please try changing the distance or add other skill categories"/>
            </el-scrollbar>
            <div class="map-container" v-if="viewType === screenType.MAP">
               <!-- THIS WILL WHERE THE MAP WILL BE PUT -->
              <img src="@/assets/images/temp-map-view.jpg">
            </div>
        </div>
      <SkillModal :show="categoriesModal" @close="setCategoriesModal(false)"/>
    </section>
</template>
<script>
import { ref } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import EmptyState from '@/core/components/ui/EmptyState.vue';
import Loader from '@/core/components/ui/Loader.vue';
import { PAGE_LIMIT } from '@/core/constants';
import screenType from '@/core/constants/display';
import CategoryItemComponent from '@/modules/project-search/components/CategoryItemComponent.vue';
import DisplayTypeComponentMobile from '@/modules/project-search/components/DisplayTypeComponentMobile.vue';
import FindJobHeader from '@/modules/project-search/components/FindJobHeader.vue';
import ProjectCardItemComponent from '@/modules/project-search/components/ProjectCardItemComponent.vue';
import SkillModal from '@/modules/project-search/components/SkillModal.vue';
// eslint-disable-next-line import/no-cycle
import { PROJECTS_STORE } from '@/store/modules/projects';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
import { USERS_STORE } from '@/store/modules/users';

export default {
  components: {
    CategoryItemComponent,
    SkillModal,
    ProjectCardItemComponent,
    DisplayTypeComponentMobile,
    Loader,
    FindJobHeader,
    EmptyState
  },

  data() {
    return {
      totalProjects: 0,
      projects: [],
      keyword: '',
      currentFilters: {
        page: 1,
        limit: PAGE_LIMIT,
        search: '',
        keywords: []
      },
      screenType,

      loadedCategories: [],
      toShowCategoryList: [],
      remainingCategoryList: [],

      categoriesModal: false,
      loading: false,
    };
  },

  watch: {
    keyword: {
      immediate: true,
      deep: true,
      handler(keyword) {
        if (keyword) {
          this.onSearch(encodeURIComponent(keyword));
        }
      }
    },

    activeUserWorkstation: {
      immediate: true,
      async handler(value) {
        if (value?.alias) {
          this.onSearchPostcode(this.userPostcode);
        }
      },
    },

    categoryList: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value.length) {
          this.separateSelectedCategories();
          this.onLoadCategoryKeywords();
        } else {
          this.toShowCategoryList = [];
          this.onSearchPostcode(this.userPostcode);
        }
      }
    }
  },

  computed: {
    ...mapGetters(['isLoading']),

    ...mapGetters(USERS_STORE, ['hasMainLayoutInitialized', 'user']),
    ...mapGetters(USER_WORKSTATION, ['activeUserWorkstation']),
    ...mapGetters(PROJECTS_STORE, [
      'findJobsSearchValue',
      'searchCategories',
      'categoryList'
    ]),

    userPostcode() {
      return this.user?.postcode;
    }
  },

  methods: {
    ...mapActions(['setIsLoading']),

    ...mapActions(PROJECTS_STORE, [
      'getProjectJobs',
      'setFindJobsSearchValue',
      'getProjectJobsCategories',
      'setCategoryList'
    ]),

    onSearch(keyword) {
      const { currentFilters } = this;
      currentFilters.search = keyword || '';
      currentFilters.postcode = this.postCodeValue || '';

      this.loadProjects(currentFilters);
    },

    onLoadCategoryKeywords() {
      const { currentFilters } = this;
      const keywords = [];

      this.categoryList.forEach((category) => {
        keywords.push(category.value);
      });

      currentFilters.keywords = keywords;
      currentFilters.postcode = this.postCodeValue || '';

      if (this.findJobsSearchValue.distanceValue !== '') {
        currentFilters.radius = this.findJobsSearchValue?.distanceValue;
      }

      this.loadProjects(currentFilters);
    },

    onSearchPostcode(postcode) {
      const { currentFilters } = this;

      currentFilters.postcode = postcode;
      this.setFindJobsSearchValue({ distanceValue: this.findJobsSearchValue?.distanceValue, postcodeValue: postcode });

      if (this.findJobsSearchValue.distanceValue !== '') {
        currentFilters.radius = this.findJobsSearchValue?.distanceValue;
      }

      if (this.categoryList.length) {
        const keywords = [];

        this.categoryList.forEach((category) => {
          keywords.push(category.value);
        });

        currentFilters.keywords = keywords;
      } else {
        currentFilters.keywords = [];
      }

      this.loadProjects(currentFilters);
    },

    onSelectDistance(radius) {
      const { currentFilters } = this;

      currentFilters.postcode = this.findJobsSearchValue?.postcodeValue;
      currentFilters.radius = radius;

      if (this.categoryList.length) {
        const keywords = [];

        this.categoryList.forEach((category) => {
          keywords.push(category.value);
        });

        currentFilters.keywords = keywords;
      } else {
        currentFilters.keywords = [];
      }

      this.loadProjects(currentFilters);
    },

    async loadProjects(filters) {
      this.loading = true;
      filters.postcode = filters.postcode ? filters.postcode.replace(/ +/g, '') : '';

      await this.getProjectJobs(filters).then((response) => {
        const { data, totalCount } = response;
        this.totalProjects = totalCount;
        this.projects = data;
      })
        .finally(() => {
          this.loading = false;
        });
    },

    separateSelectedCategories() {
      const numberOfShownCategory = 5;
      if (this.categoryList.length) {
        this.toShowCategoryList = this.categoryList.reduce((mappedArray, item, index) => {
          if (index < numberOfShownCategory) {
            mappedArray.push(item);
          }
          return mappedArray;
        }, []);
        this.remainingCategoryList = this.categoryList.reduce((mappedArray, item, index) => {
          if (index >= numberOfShownCategory) {
            mappedArray.push(item);
          }
          return mappedArray;
        }, []);
      }
    },

    setCategoriesModal(event) {
      this.categoriesModal = event;
    }
  },

  setup() {
    const viewType = ref(screenType.LIST);
    const postCodeValue = ref('');
    const value = ref('');
    const cardCount = ref(5);

    function setView(event) {
      viewType.value = event;
    }

    return {
      cardCount,
      value,
      viewType,
      postCodeValue,
      // skillsCount,
      // handleSelect,
      setView,
      // querySearch,
    };
  }
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

:deep(.el-scrollbar__bar) {
    display: block !important;
}
:deep(.el-scrollbar__bar.is-vertical) {
    top: 0;
    right: 0;
    width: 6px !important;
}

.find-jobs-container {
    margin-left: 10px;
    margin-right: 20px;
    display:flex;
    flex-direction: column;
    gap: 1rem;
    height: calc(100% - 3rem);
    overflow: hidden;
    max-width: 1230px;

    .skills-container {
        // display: grid;
        // grid-template-columns: repeat(auto-fill, minmax(176px, 1fr));
        gap: 1rem;
        display: flex;
        align-items: center;

        .plus-count {
            display:flex;
            justify-content: center;
            align-items: center;
            width: 38px;
            height:38px;
            border: 1px solid rgba(12, 15, 74, 0.2);
            border-radius: 100px;
            box-sizing: border-box;
            cursor: pointer;
            .count {
                font-size: 12px;
                font-weight: 700;
                line-height: 16px;
                letter-spacing: 0.4000000059604645px;
                text-align: center;
            }
        }
    }

    .find-jobs-content {
        // height: calc(100% - 4.2rem);
        height: calc(100% - 9.2rem);
        position: relative;

        .el-scrollbar {
            // width: 100%;
            min-width: 400px;
            :deep(.el-scrollbar__view) {
                display:flex;
                flex-direction: column;
                gap: 1.5rem;
                height: auto !important;
                padding: 0 .7rem 2rem 0;
            }
        }
        .el-scrollbar.grid-layout {
            :deep(.el-scrollbar__view) {
                display:grid;
                // grid-template-columns: repeat(2, 1fr);
                grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
            }
        }

        .map-container {
            width: 100%;
            border-radius: 16px;
            overflow-y: scroll;
            height: calc(100% - 4rem);
            img {
                width: 100%;
                border-radius: 16px;
                object-fit: cover;
            }
        }
    }

    .find-jobs-content.flex-row-layout {
        display: flex;
        gap: 1.5rem;
    }
    .find-jobs-content.changeMaxWidth {
      max-width: 1230px;
    }
}

@include media-md-max-width() {
  .find-jobs-container {
    margin: 0 25px;
    gap:.2rem;
    .find-jobs-content {
      margin-top: 1rem;
      height: calc(100% - 8.6rem);
    }

    .header-container {
      flex-direction: column;
      align-items: flex-start;
      gap: .7rem;
      // .column-two {
      //   display: none;
      // }
    }
  }
}
@include media-sm-max-width() {
  .find-jobs-container {
    gap: 0;
    height: 100%;
    .find-jobs-content {
      height: calc(100% - 7.9rem);
      .el-scrollbar {
        min-width: 300px;
      }
    }
    .header-container {
      .column-one {
        flex-direction: column;
        width: 100%;

        .el-input {
          width: 100% !important;
        }
        .el-select {
          width: 100%;
          :deep(.el-input) {
            width: 100%;
          }
        }
      }
    }
  }
  .address-filter-container {
    width: 100%;
  }
}

// @media screen and (max-width: 685px) {
//   .find-jobs-container {
//     .find-jobs-content {
//       height: calc(100% - 12.6rem);
//     }
//     .header-container {
//       .column-one {
//         flex-direction: column;
//         width: 100%;

//         .el-input {
//           width: 100% !important;
//         }
//         .el-select {
//           width: 100%;
//           :deep(.el-input) {
//             width: 100%;
//           }
//         }
//       }
//     }
//   }
//   .address-filter-container {
//     width: 100%;
//   }
// }

@include media-xs-max-width() {
  .find-jobs-container {
    margin: 0 10px;
    .find-jobs-content {
      height: calc(100% - 6.9rem);
      margin-top: 0;

      .el-scrollbar.grid-layout {
        :deep(.el-scrollbar__view) {
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          gap: 1rem;
        }
      }
      .el-scrollbar {
        :deep(.el-scrollbar__view) {
          gap: 1rem;
        }
      }
    }
  }
}
</style>
